<template>
  <div data-vue-component-name="Events">
    <img
      width="4096"
      height="2731"
      src="backgrounds/Events.png"
      alt="page background image"
      :style="{
        filter: !isNull(hoveredItemIndex)
          ? 'brightness(0.6)'
          : null,
      }"
    >

    <ButtonBack @click="goBack" />
    <LabelEvents
      :style="{
        opacity: !isNull(activeItemIndex)
          ? 0
          : 1
      }"
    />

    <!-- red moving rectangle -->
    <AppWrapper
      bg-color="rgba(218, 41, 28, 0.5)"
      :style="redRectangleStyles"
    />

    <!-- items container -->
    <ul
      :style="{
        pointerEvents: isNull(activeItemIndex)
          ? 'auto'
          : 'none',
      }"
    >
      <li
        v-for="(event, index) in eventsList"
        :key="index"
        :style="!isNull(activeItemIndex) && (activeItemIndex === index
          ? {
              transform: `translateY(${calcActiveElementOffset}%)`,
            }
          : {
              transform: 'translateY(-50vh)',
              opacity: 0,
              pointerEvents: 'none',
            }
        )"
        :ref="itemRef => setItemRef(itemRef, index)"
      >
        <EventNumber :is-visible="itemCondition(index)" :number="index + 1" />

        <EventItem
          :event="event"
          :is-hovered="itemCondition(index)"
          @mouseenter="isNull(activeItemIndex) && toggleHoveredItem(true, index)"
          @mouseleave="isNull(activeItemIndex) && toggleHoveredItem(false, index)"
          @click="onItemClick(index)"
        />
      </li>
    </ul>

    <transition name="fade-offsetY" appear>
      <EventItemInfo
        v-if="activeEvent"
        :image="activeEvent.eventSinglePage.image"
        :content="activeEvent.eventSinglePage.content"
      />
    </transition>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { AppWrapper, ButtonBack } from '@xsys2/components';
import { metaController } from '@/modules';
import { isNull } from '@xsys2/functions';

// meta
metaController({
  title: 'Events Page',
  link: [
    {
      rel: 'preload',
      href: 'backgrounds/Events.png',
      as: 'image',
    },
  ],
});

const router = useRouter();
const store = useStore();

store.dispatch('events/getData');
const eventsList = computed(() => store.state.events.eventsList);

// back button
const goBack = () => {
  if (isNull(activeItemIndex.value)) {
    router.push({ name: 'Home' });
  } else {
    activeItemIndex.value = null;
    hoveredItemIndex.value = null;
  }
};

const hoveredItemIndex = ref(null);
const activeItemIndex = ref(null);
const toggleHoveredItem = (isHovered, itemIndex) => {
  hoveredItemIndex.value = isHovered
    ? itemIndex
    : null;
};
const onItemClick = (index) => {
  activeItemIndex.value = index;
};
const redRectangleStyles = computed(() => {
  const styles = {};

  if (isNull(activeItemIndex.value)) {
    styles.right = isNull(hoveredItemIndex.value)
      ? '63vw'
      : 0;
  } else {
    styles.right = 0;
    styles.width = '100vw';
  }

  return styles;
});
const itemCondition = (index) => {
  let condition;

  if (isNull(activeItemIndex.value)) {
    condition = !isNull(hoveredItemIndex.value)
      && hoveredItemIndex.value === index;
  } else {
    condition = activeItemIndex.value === index;
  }

  return condition;
};
const setItemRef = (itemRef, index) => {
  if (itemRef) {
    eventsList.value[index].ref = itemRef;
  }
};

const activeEvent = computed(() => eventsList.value[activeItemIndex.value]);
const calcActiveElementOffset = computed(() => {
  const node = eventsList.value[activeItemIndex.value].ref;
  const { top, height } = node.getBoundingClientRect();

  return -((top - 93) / height) * 100;
});
</script>

<!-- transitions -->
<style scoped lang="scss">
.fade-offsetY-enter-active,
.fade-offsetY-leave-active {
  transition: all 1.5s ease-in-out;
  transform: translateY(0);

  ::v-deep([data-vue-component-name="AppImage"]) {
    transition: all 1.5s ease-in-out;
    margin-top: 0;
  }
}

.fade-offsetY-enter-from,
.fade-offsetY-leave-to {
  opacity: 0;
  transform: translateY(5vw);

  ::v-deep([data-vue-component-name="AppImage"]) {
    margin-top: 15vw;
  }
}
</style>

<style scoped lang="scss">
[data-vue-component-name="Events"] {
  width: 100vw;
  height: 100vh;
  position: relative;

  img {
    width: inherit;
    height: inherit;
    object-fit: cover;
    transition: all 1s ease-in-out;
  }

  [data-vue-component-name="ButtonBack"] {
    position: absolute;
    z-index: 2;
    top: 28px;
    left: 30px;
  }

  [data-vue-component-name="LabelEvents"] {
    position: absolute;
    z-index: 1;
    bottom: 10px;
    right: 40px;
    width: 28.5vw;
    height: auto;
    transition: opacity 1s ease-in-out;
  }

  // red moving rectangle
  [data-vue-component-name="AppWrapper"] {
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    width: 20.8vw;
    transition: all 1s ease-in-out 0.1s;
  }

  ul {
    position: absolute;
    z-index: 3;
    top: 20.3%;
    width: 100%;

    li {
      display: flex;
      opacity: 1;
      transition: all 1.5s ease-in-out;
      @include spaceY(3.4vw);

      [data-vue-component-name="EventItem"] {
        margin-left: 21.7vw;
      }
    }
  }

  [data-vue-component-name="EventItemInfo"] {
    position: absolute;
    z-index: 2;
    right: 10.2vw;
    bottom: 0;
    left: 12vw;
    top: 30%;
  }
}
</style>
